import { AppThunkAction } from '../../store/index';
import { Reducer } from 'redux';
import { Api, getSessionInfo } from '../../api/Api';
import {
    AuthAction
} from './authActions';
import * as checkRequestActions from '../checkRequests/checkRequestReducer';
import * as tripActions from '../trips/tripReducer';
import {
    TENANT_ID, GRAPH_API_LENGTH, CLIENT_ID
} from '../../constants/constants';
import { IUser } from '../../components/index';
type KnownAction = AuthAction;

const api = new Api();

export interface IAuthState {
    isLoggingIn: boolean;
    hasValidSession: boolean;
    users: any[];
    currentUser: IUser | null;
    currentUserHasProfile: boolean;
    primaryManagerEmail: string;
    primaryManagerName: string;
    primaryManager: any;
}
export const unloadedState: IAuthState = {
    isLoggingIn: false,
    hasValidSession: false,
    users: [],
    currentUser: null,
    currentUserHasProfile: false,
    primaryManagerEmail: '',
    primaryManagerName: '',
    primaryManager: null
}


export enum AuthActionTypes {
    REDIRECT_TO_AZURE_AD = 'REDIRECT_TO_AZURE_AD',
    GET_AZURE_AUTHENTICATION_TOKEN = 'GET_AZURE_AUTHENTICATION_TOKEN',
}
export interface RedirectToAzureADAction {
    type: AuthActionTypes.REDIRECT_TO_AZURE_AD;
}
export interface GetAzureAuthenticationTokenAction {
    type: AuthActionTypes.GET_AZURE_AUTHENTICATION_TOKEN;
}
// STATE - This defines the type of data maintained in the Redux store.
function generateStateValue(length: number = 16): string {
    const randomValues = new Uint32Array(length);
    window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues, (val) => val.toString(16)).join('');
}

// Step 1: Generate a random code_verifier of length 43
function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

const code_verifier = generateRandomString(GRAPH_API_LENGTH);

// Step 2: Create the code_challenge using the S256 method
async function generateCodeChallenge(verifier) {
    const encoder = new TextEncoder();
    const data = encoder.encode(verifier); // convert string to ArrayBuffer
    const hash = await crypto.subtle.digest('SHA-256', data);
    const base64UrlHash = base64UrlEncode(hash);
    return base64UrlHash;
}

function base64UrlEncode(buffer) {
    const byteArray = new Uint8Array(buffer);
    let base64 = btoa(String.fromCharCode.apply(null, byteArray));
    return base64
        .replace(/\+/g, '-')  // Globally replace '+' with '-'
        .replace(/\//g, '_')  // Globally replace '/' with '_'
        .replace(/=+$/, '');
}

export const actionCreators = {
    initializeApp: (): AppThunkAction<any> => async (dispatch) => {
        await dispatch(actionCreators.getCurrentUser());
        await dispatch(tripActions.actionCreators.getMiscExpenseCategories());
        await dispatch(tripActions.actionCreators.getCurrencies());
        await dispatch(tripActions.actionCreators.getCategories());
        await dispatch(tripActions.actionCreators.getTrips());
        await dispatch(checkRequestActions.actionCreators.getCheckRequests());
        await dispatch(checkRequestActions.actionCreators.getCheckRequestsToApprove());
    },
    redirectToAzureAD: (): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({
            type: 'GET_AZURE_AUTHENTICATION_TOKEN',
            isLoggingIn: true,
        });

        // triggered from login button.
        const redirectUri = encodeURIComponent(`https://${window.location.host}`);
        const scope = encodeURIComponent('openid profile email offline_access');
        const state = encodeURIComponent(generateStateValue());
        const responseType = 'code';
        const codeChallengeMethod = 'S256';

        generateCodeChallenge(code_verifier).then(code_challenge => {
            // save the code_verifier in session storage because we need it later to get the token (see getAuthenticationToken())
            window.sessionStorage.setItem('codeVerifier', code_verifier);
            const authUrl = `https://login.microsoftonline.com/${TENANT_ID}/oauth2/v2.0/authorize?client_id=${CLIENT_ID}&response_type=${responseType}&redirect_uri=${redirectUri}&response_mode=query&scope=${scope}&state=${state}&code_challenge=${encodeURIComponent(code_challenge)}&code_challenge_method=${codeChallengeMethod}`;

            window.location.href = authUrl;

            dispatch({
                type: 'REDIRECT_TO_AZURE_AD'
            });
        });
    },
    getAuthenticationToken: (code: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        let codeVerifier = window.sessionStorage.getItem('codeVerifier');
        return api.getAuthenticationToken(code, codeVerifier)
            .then((response) => {
                window.localStorage.setItem('id_token', response.id_token);
                //  window.sessionStorage.setItem('refresh_token', response.refresh_token);
                window.sessionStorage.setItem('session_token', response.access_token);
                window.sessionStorage.removeItem('codeVerifier');
            });
    },
    logout: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        window.sessionStorage.removeItem('session_token');
        window.localStorage.removeItem('id_token');
        //window.sessionStorage.removeItem('refresh_token');
        window.sessionStorage.removeItem('profile');
        window.sessionStorage.removeItem('appUser');
        window.sessionStorage.removeItem('profileImage');

        const redirectUri = encodeURIComponent(`https:\\\\${window.location.host}`);
        dispatch({
            type: 'CHECK_VALID_SESSION',
            hasValidSession: false,
        });

        const tenantId = TENANT_ID;
        window.location.href = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/logout?post_logout_redirect_uri=${redirectUri}`;
    },
    checkValidSession: (): AppThunkAction<KnownAction> => (dispatch) => {
        let sessionToken = window.sessionStorage.getItem('session_token');
        dispatch({
            type: 'CHECK_VALID_SESSION',
            hasValidSession: sessionToken !== undefined,
        });
    },
    getCurrentUser: (): AppThunkAction<any> => async (dispatch, getState) => {

        let currentUser = getSessionInfo();
        if (!currentUser) {
            return;
        }

        dispatch({ type: 'CHECK_VALID_SESSION', hasValidSession: true });

        // get the user's profile info from SQL database and roll into token. 
        try {
            const result: any = await api.getCurrentUser(currentUser.name, currentUser.email)
                .then((result: any) => {

                    let appUser = result.appUser;

                    // set the expense_token to be the same as the session token.
                    let jwt = result.token;
                    sessionStorage.setItem('expense_token', jwt);

                    // combine their AD profile and SQL profile:
                    currentUser.id = appUser.id; // id on SQL server.
                    currentUser.azureId = ''; // id on Azure AD.          

                    currentUser.primaryManagerEmail = appUser.primaryManagerEmail;
                    currentUser.companyNumber = parseInt(appUser.companyNumber);
                    currentUser.itCostCenter = appUser.itCostCenter;

                    // set the employeeid to be the users vendor number.
                    currentUser.vendorNumber = appUser.vendorNumber;
                    currentUser.isAuditor = appUser.isAuditor;
                    currentUser.isUSemployee = appUser.isUSemployee;

                    dispatch({
                        type: 'GET_CURRENT_USER_SUCCESS',
                        currentUser: currentUser,
                        currentUserHasProfile: appUser.companyNumber && appUser.primaryManagerEmail && appUser.vendorNumber && appUser.itCostCenter
                    });
                });
        } catch (error) { }
    },
    loadUserList: (inputValue, callback): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (!inputValue) {
            return;
        }
        dispatch({
            type: 'GET_ALL_USERS',
            users: []
        });
        api.getAutocompleteUser(inputValue)
            .then(response => response.json())
            .then(data => {
                const users = data.value;
                // console.log('Fetched users: ', users);
                return users;
            })
            .then((results: any) => {
                let users: any = [];
                for (let i = 0; i < results.length; i++) {
                    // if results[i].mail does not contain "us.schott.com" then continue."
                    // hack: exception to allow Hakan Gerdan's name (who is not a US user) to show up in list.
                    if (!results[i].mail.includes('gerdan') && !results[i].mail.includes('us.schott.com')) {
                        continue;
                    }
                    if (!results[i].employeeId) {
                        //console.log(results[i].displayName + ' does not have an employeeId');
                        continue;
                    }
                    users.push({
                        label: results[i].displayName,
                        value: results[i].mail, vendorNumber: results[i].employeeId
                    });
                }

                users && users.filter((i: any) =>
                    i.label.toLowerCase().includes(inputValue.toLowerCase())
                );

                dispatch({
                    type: 'GET_ALL_USERS',
                    users: users
                });
            })
            .catch(error => console.log('Error fetching users: ', error));
    },
};

// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IAuthState> = (state: IAuthState | undefined, action: KnownAction): IAuthState => {

    switch (action.type) {
        case 'GET_AZURE_AUTHENTICATION_TOKEN':
            return {
                ...state,
                isLoggingIn: action.isLoggingIn
            };
        case 'REDIRECT_TO_AZURE_AD':
            return {
                ...state,
                isLoggingIn: true
            };
        case 'CHECK_VALID_SESSION':
            return {
                ...state,
                hasValidSession: action.hasValidSession
            };
        case 'GET_ALL_USERS':
            return {
                ...state,
                users: action.users
            };
        case 'GET_CURRENT_USER_SUCCESS':
            return {
                ...state,
                currentUser: action.currentUser,
                currentUserHasProfile: action.currentUserHasProfile
            };
        case 'HANDLE_PRIMARY_CHANGE':
            return {
                ...state,
                primaryManagerEmail: action.primaryManagerEmail,
                primaryManagerName: action.primaryManagerName,
                primaryManager: action.primaryManager
            };
        default:
            break;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;

};